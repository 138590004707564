import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic06 from '../assets/images/pic06.jpg'

const Surveys = props => (
  <Layout>
    <Helmet>
      <title>Surveys - Horovatin Consulting Ltd.</title>
      <meta name="description" content="Surveys Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Salary Surveys</h1>
          </header>
          <span className="image main">
            <img
              src={pic06}
              alt="A close-up of a large pink daisy-like flower with a yellow centre."
            />
          </span>
          <p>
            We have been working with Alberta-based clients to develop and
            conduct customized Salary Surveys, helping them to define fair and
            competetive salaries for their employees while being accountable to
            the organization and its stakeholders.
          </p>
          <p>
            From inception through to the final report we work with you to
            understand the numbers so you can be confident in your compensation
            decisions.
          </p>
          <p>We give meaning to the data.</p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Surveys
